import type { ThemeDefinition } from "vuetify";

export const themeLightColorSchema: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#F9F9FA",
    surface: "#FFFFFF",
    "on-surface": "#000",
    "on-background": "#000",
    primary: "#50228F",
    error: "#da211c",
    warning: "#FF9800",
    accent: "#ff4dc2",
    info: "#a3f3ff",
  },
  variables: {
    "field-input-padding-bottom": "4px",
    "field-input-padding-top": "4px",
    "input-control-height": "35px",
  },
};

export const themeDarkColorSchema: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#50228F",
    accent: "#ff4dc2",
  },
};

export const themes = {
  light: themeLightColorSchema,
  dark: themeDarkColorSchema,
};

export type ThemeName = keyof typeof themes;
